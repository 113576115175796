import React, { useContext, useEffect, useState, useMemo } from "react";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import ListUi from "../ui/List/ListUi";
import ListItemUi from "../ui/ListItem/ListItemUi";

const coloreLabel = (color) => {
  if (!color) return "";

  if (color.label) {
    return `${color.id} - ${color.label}`;
  }

  return color.id;
};

export default function Step4Component() {
  const context = useContext(ConfiguratorContext);

  const [price, setPrice] = useState(null);

  const configurationData = useMemo(() => {
    const client_code = window.location.pathname.split("/")[2];

    return {
      client_code: client_code,
      product_code: context.state.productData.id,
      base_code: context.configuration.data.base,
      product_material_general_code: context.configuration.data.materiale,
      product_material_back_code: context.configuration.data.materialeSchienale,
      base_material_general_code: context.configuration.data.textureBase?.id,
      base_material_gas_code: context.configuration.data.textureBaseGas?.id,
      quilting: context.configuration.data.trapuntatura?.value,
      base_options: [
        context.configuration.data.optionBase_gas_lift ? "gas_lift" : null,
        context.configuration.data.optionBase_memory_return
          ? "memory_return"
          : null,
      ].filter((o) => !!o),
    };
  }, [context.configuration.data, context.state]);

  const dimensions = useMemo(() => {
    if (!context.selectedBase) return null;
    if (!context.state.productData) return null;

    const secureParseFloat = (value) => {
      const parsed = parseFloat(value);
      return isNaN(parsed) || !isFinite(parsed) || parsed <= 0 ? 0 : parsed;
    };

    const product_size_back_height = secureParseFloat(
      context.state.productData.size_back_height
    );
    const product_size_armrests_height = secureParseFloat(
      context.state.productData.size_armrests_height
    );
    const product_size_width = secureParseFloat(
      context.state.productData.size_width
    );
    const product_size_internal_width = secureParseFloat(
      context.state.productData.size_internal_width
    );
    const product_size_length = secureParseFloat(
      context.state.productData.size_length
    );
    const product_size_internal_length = secureParseFloat(
      context.state.productData.size_internal_length
    );
    const product_size_thickness = secureParseFloat(
      context.state.productData.size_thickness
    );
    const base_size_width = secureParseFloat(context.selectedBase?.size_width);
    const base_size_length = secureParseFloat(
      context.selectedBase?.size_length
    );
    const base_size_height_min = secureParseFloat(
      context.selectedBase?.size_height_min
    );
    const base_size_height_max = secureParseFloat(
      context.selectedBase?.size_height_max
    );
    
    const memory_return = context.selectedBase?.option_memory_return;
    const gas_lift = context.configuration.data.optionBase_gas_lift

    const altezza_minima =
      product_size_back_height + product_size_thickness + base_size_height_min;
    const altezza_massima =
      product_size_back_height + product_size_thickness + base_size_height_max;
    const larghezza = product_size_internal_width;
    const lunghezza = product_size_internal_length;
    const larghezza_base = context.selectedBase?.size_width;
    const lunghezza_base = context.selectedBase?.size_length;
    const altezza_braccioli = product_size_armrests_height;
    const altezza_seduta_min = product_size_thickness + base_size_height_min;
    const altezza_seduta_max = product_size_thickness + base_size_height_max;
    const ingombro_altezza = Math.max(altezza_massima, altezza_minima);
    const ingombro_larghezza = Math.max(product_size_width, base_size_width);
    const ingombro_lunghezza = Math.max(product_size_length, base_size_length);

    return {
      altezza_minima:
        altezza_minima && altezza_minima < altezza_massima
          ? altezza_minima
          : null,
      altezza_massima: Math.max(altezza_massima, altezza_minima),
      altezza_braccioli,
      larghezza,
      lunghezza,
      larghezza_base,
      lunghezza_base,
      altezza_seduta_min:
        altezza_seduta_min && altezza_seduta_min < altezza_seduta_max
          ? altezza_seduta_min
          : null,
      altezza_seduta_max: Math.max(altezza_seduta_max, altezza_seduta_min),
      ingombro_altezza,
      ingombro_larghezza,
      ingombro_lunghezza,
      memory_return,
      gas_lift,
    };
  }, [context.selectedBase, context.state.productData]);

  const box = useMemo(() => {
    if (!context.selectedBase) return null;
    if (!context.state.productData) return null;

    const secureParseFloat = (value) => {
      const parsed = parseFloat(value);
      return isNaN(parsed) || !isFinite(parsed) || parsed <= 0 ? 0 : parsed;
    };

    const product_box_weight = secureParseFloat(
      context.state.productData.box_weight
    );
    const product_box_pieces = secureParseFloat(
      context.state.productData.box_pieces
    );
    const product_box_volume_cbm = secureParseFloat(
      context.state.productData.box_volume_cbm
    );

    const base_box_weight = secureParseFloat(context.selectedBase?.box_weight);
    const base_box_pieces = secureParseFloat(context.selectedBase?.box_pieces);
    const base_box_volume_cbm = secureParseFloat(
      context.selectedBase?.box_volume_cbm
    );

    return {
      box_weight: base_box_weight || product_box_weight,
      box_pieces: base_box_pieces || product_box_pieces,
      box_volume_cbm: base_box_volume_cbm || product_box_volume_cbm,
    };
  }, [context.selectedBase, context.state.productData]);

  /**
   * Richiesta prezzo al cloud
   */
  useEffect(() => {
    const run = async () => {
      const response = await fetch(`/product_price`, {
        method: "POST",
        body: JSON.stringify(configurationData),
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
      });
      const responseJson = await response.json();

      if (responseJson.price) setPrice(responseJson.price);
    };

    run();
  }, []);

  const onClickBackBase = () => {
    context.setStep(1);
  };
  const onClickBackMaterialeSedile = () => {
    context.setStep(2);

    context.setCurrentSelect(1);
  };
  const onClickBackColoreSedile = () => {
    context.setStep(2);
    context.setCurrentSelect(2);
  };
  const onClickBackMaterialeSchienale = () => {
    context.setStep(2);
    context.setCurrentSelect(3);
  };

  const onClickBackColoreSchienale = () => {
    context.setStep(2);
    context.setCurrentSelect(4);
  };

  const onClickBackTrapuntatura = () => {
    context.setStep(2);
    context.setCurrentSelect(5);
  };

  const onClickBackSedile = () => {
    context.setStep(2);
  };
  const onClickBackMaterialeBase = () => {
    context.setStep(3);
  };

  const showSizeValue = (value) => {
    if (context.state.clientUnitSize == "inch") {
      // convert value from cm to inch
      return roundTo(value * 0.393701, 1);
    } else {
      return roundTo(value, 1);
    }
  };

  const showSizeUnit = () => {
    if (context.state.clientUnitSize == "inch") {
      return "in";
    } else {
      return "cm";
    }
  };

  const catMaterialeSedile =
    context.selectedMaterialeSedile?.material_typology &&
    context.selectedMaterialeSedile?.material_typology != "general"
      ? " / " +
        window.jsLocale(
          `enum_material_typology_${context.selectedMaterialeSedile?.material_typology}`
        )
      : "";
  const catMaterialeSchienale =
    context.selectedMaterialeSchienale?.material_typology &&
    context.selectedMaterialeSchienale?.material_typology != "general"
      ? " / " +
        window.jsLocale(
          `enum_material_typology_${context.selectedMaterialeSchienale?.material_typology}`
        )
      : "";
  const catMaterialeBase =
    context.selectedMaterialeBase?.material_typology &&
    context.selectedMaterialeBase?.material_typology != "general"
      ? " / " +
        window.jsLocale(
          `enum_material_typology_${context.selectedMaterialeBase?.material_typology}`
        )
      : "";

  return (
    <div className="print-container">
      <div className="print-container__image"></div>
      <div className="print-container__content">
        <ListUi>
        <ListItemUi
          label={window.jsLocale("configurator_product_general_model")}
          value={`${window.jsLocale(
            `enum_product_typology_${context.state.productData.typology}`
          )} ${context.state.productData.label}`}
        ></ListItemUi>
        {context.numberBasi > 1 && (
          <ListItemUi
            onClick={onClickBackBase}
            label={window.jsLocale("configurator_base")}
            value={
              context.selectedBase?.label +
              " - " +
              context.selectedBase?.typologyLabel +
              (context.configuration.data.optionBase_gas_lift ? " - Gas lift" : "") +
              (context.configuration.data.optionBase_memory_return ? " - Memory return" : "")
            }
          ></ListItemUi>
        )}


          <ListItemUi
            onClick={onClickBackMaterialeSedile}
            label={window.jsLocale("configurator_product_general_material")}
            value={context.configuration.data.materiale + catMaterialeSedile}
          ></ListItemUi>
          <ListItemUi
            onClick={onClickBackColoreSedile}
            label={window.jsLocale("configurator_product_general_color")}
            value={coloreLabel(context.selectedColoreSedile)}
          ></ListItemUi>
          {context.configuration.data.materialeSchienale && (
            <ListItemUi
              onClick={onClickBackMaterialeSchienale}
              label={window.jsLocale("configurator_product_back_material")}
              value={
                context.configuration.data.materialeSchienale +
                catMaterialeSchienale
              }
            ></ListItemUi>
          )}
          {context.configuration.data.coloreSchienale && (
            <ListItemUi
              onClick={onClickBackColoreSedile}
              label={window.jsLocale("configurator_product_back_color")}
              value={coloreLabel(context.selectedColoreSchienale)}
            ></ListItemUi>
          )}

          <ListItemUi
            onClick={onClickBackTrapuntatura}
            label={window.jsLocale("configurator_quilting")}
            value={
              context.configuration.data.trapuntatura
                ? context.configuration.data.trapuntatura.label.toUpperCase()
                : window.jsLocale("cta_no").toUpperCase()
            }
          ></ListItemUi>
          {!context.state.productData.force_texture_to_base && (
            <ListItemUi
              onClick={onClickBackMaterialeBase}
              label={window.jsLocale("configurator_base_material")}
              value={
                context.configuration.data.textureBase?.label + catMaterialeBase
              }
            ></ListItemUi>
          )}
          {!context.state.productData.force_texture_to_base &&
            context.configuration.data.textureBaseGas && (
              <ListItemUi
                onClick={onClickBackMaterialeBase}
                label={window.jsLocale("configurator_base_material_gas")}
                value={
                  context.configuration.data.textureBaseGas?.label +
                  catMaterialeBase
                }
              ></ListItemUi>
            )}
          {/* <ListItemUi
            label={window.jsLocale("configurator_order_code")}
            value="S3990KD106LRBRDA00"
          ></ListItemUi> */}
          {price && (
            <ListItemUi
              label={window.jsLocale("configurator_price")}
              value={price.string}
            ></ListItemUi>
          )}
        </ListUi>
        {dimensions && (
          <div className="info-product">
            <div className="info-product__cols">
              <div className="info-product__col">
                <span className="info-product__col-title">
                  {window.jsLocale("configurator_size")}{" "}
                  <span>({showSizeUnit()})</span>
                </span>
                <div className="info-product__dimensions">
                  <div className="info-product__dimensions-col">
                    <div className="info-product__img">
                      <span className="info-product__width info-product__width--top">
                        {showSizeValue(dimensions.larghezza)}
                      </span>
                      {context.state.productData.typology === "sgabello" ? (
                        <img src="/images/sgabello-front.svg" alt="" />
                      ) : (
                        <img src="/images/sedia-front.svg" alt="" />
                      )}
                      <span className="info-product__width">
                        {showSizeValue(dimensions.larghezza_base)}
                      </span>
                    </div>

                    <span className="info-product__height">
                      {showSizeValue(dimensions.altezza_massima)}{" "}
                      {dimensions.altezza_minima && <br />}{" "}
                      {dimensions.altezza_minima &&
                        `/ ${showSizeValue(dimensions.altezza_minima)}`}
                    </span>
                  </div>
                  <div className="info-product__dimensions-col">
                    <div className="info-product__img">
                      <span className="info-product__width info-product__width--top">
                        {showSizeValue(dimensions.lunghezza)}
                      </span>
                      {context.state.productData.typology === "sgabello" ? (
                        <img src="/images/sgabello.svg" alt="" />
                      ) : (
                        <img src="/images/sedia.svg" alt="" />
                      )}
                      <span className="info-product__width">
                        {showSizeValue(dimensions.lunghezza_base)}
                      </span>
                    </div>
                    <span className="info-product__height">
                      {showSizeValue(dimensions.altezza_seduta_max)}{" "}
                      {dimensions.altezza_seduta_min && <br />}{" "}
                      {dimensions.altezza_seduta_min &&
                        `/ ${showSizeValue(dimensions.altezza_seduta_min)}`}
                    </span>
                  </div>
                </div>
              </div>

              <div className="info-product__col">
                <div className="info-product__details">
                  <div className="info-product__details-col">
                    <span className="info-product__col-title">
                      {window.jsLocale("configurator_encumbrance")}{" "}
                      <span>({showSizeUnit()})</span>
                    </span>
                    <div className="info-product__ingombro">
                      <div className="info-product__img">
                        {context.state.productData.typology === "sgabello" ? (
                          <img src="/images/sgabello-ingombro.svg" alt="" />
                        ) : (
                          <img src="/images/sedia-ingombro.svg" alt="" />
                        )}
                        <div className="info-product__ingombro-dimensions">
                          <span className="info-product__width">
                            {showSizeValue(dimensions.ingombro_larghezza)}
                          </span>
                          <span className="info-product__width">
                            {showSizeValue(dimensions.ingombro_lunghezza)}
                          </span>
                        </div>
                      </div>
                      <span className="info-product__height">
                        {showSizeValue(dimensions.ingombro_altezza)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-product__cols">
              <div className="info-product__box">
                <span className="info-product__col-title">
                  {window.jsLocale("configurator_box")}
                </span>
                {/* <div className="info-product__box-col">
                  <img src="/images/imballo.svg" alt="" />
                </div> */}
                {/* TODO Aggiungere da mobile cartone  */}
                <div className="info-product__box-col">
                  <p>1 Carton</p>
                  {box.box_weight ? <p>{box.box_weight} Kg box</p> : ""}
                  {box.box_volume_cbm ? <p>CBM {box.box_volume_cbm}</p> : ""}
                  {box.box_pieces ? <p>{box.box_pieces} pcs / box</p> : ""}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function roundTo(n, digits) {
  if (digits === undefined) {
    digits = 0;
  }

  var multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  var test = Math.round(n) / multiplicator;
  return +test.toFixed(digits);
}
