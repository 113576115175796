import React, { useContext, useState, useMemo } from "react";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import SidebarColorsUi from "../ui/SidebarColors/SidebarColorsUi";
import SelectUi from "../ui/Select/SelectUi";
import SelectListUi from "../ui/SelectList/SelectListUi";
import IconUi from "../ui/Icon/IconUi";
import useIsMobile from "../hooks/useIsMobile";

const coloreLabel = (color) => {
  if (!color) return ''

  if (color.label) {
    return `${color.id} - ${color.label}`
  }

  return color.id
}

export default function Step2Component() {
  const isMobile = useIsMobile();
  const context = useContext(ConfiguratorContext);

  const [coloreSedile, setColoreSedile] = useState(coloreLabel(context.selectedColoreSedile));
  const [coloreSchienale, setColoreSchienale] = useState(coloreLabel(context.selectedColoreSchienale));
  const onHoverColoreSedile = (color) => {
    if (!isMobile) {
      setColoreSedile(coloreLabel(color));
    }
  };
  const onLeaveColoreSedile = (color) => {
    if (!isMobile) {
      setColoreSedile(coloreLabel(color));
    }
  };

  const onHoverColoreSchienale = (color) => {
    if (!isMobile) {
      setColoreSchienale(coloreLabel(color));
    }
  };
  const onLeaveColoreSchienale = (color) => {
    if (!isMobile) {
      setColoreSchienale(coloreLabel(color));
    }
  };

  const onClickMaterialeSedile = (materiale) => {
    context.setData({
      materiale: materiale.id,
      coloreSedile: null,
      trapuntatura: null,
    });
    context.setCurrentSelect(2);
  };
  const onClickColoreSedile = (color) => {
    context.setData({
      coloreSedile: color.id,
    });
    setColoreSedile(coloreLabel(color));
    context.setOldCurrentSelect(context.currentSelect);

    context.setCurrentSelect(0);

    if (isMobile) {
      context.setSidebarOpen(!context.sidebarOpen);
    }
  };

  const onClickMaterialeSchienale = (materiale) => {
    if (materiale.id == "__SEDUTA__") {
      context.setData({
        materialeSchienale: context.configuration.data.materiale,
        coloreSchienale: context.configuration.data.coloreSedile,
      });
      setColoreSchienale(coloreLabel(context.selectedColoreSedile));
      context.setOldCurrentSelect(context.currentSelect);

      context.setCurrentSelect(0);
    } else {
      context.setData({
        materialeSchienale: materiale.id,
        coloreSchienale: null,
      });
      context.setCurrentSelect(4);
    }
  };
  const onClickColoreSchienale = (color) => {
    context.setData({
      coloreSchienale: color.id,
    });
    context.setOldCurrentSelect(context.currentSelect);
    context.setCurrentSelect(0);
    setColoreSchienale(coloreLabel(color));
    if (isMobile) {
      context.setSidebarOpen(!context.sidebarOpen);
    }
  };

  const onClickTrapuntatura = (option) => {
    context.setData({
      trapuntatura: option,
    });

    context.setCurrentSelect(0);
    if (isMobile) {
      context.setSidebarOpen(!context.sidebarOpen);
    }
  };

  const typology =
    context.selectedMaterialeSedile?.material_typology &&
    context.selectedMaterialeSedile?.material_typology != "general"
      ? "(" +
        window.jsLocale(
          `enum_material_typology_${context.selectedMaterialeSedile?.material_typology}`
        ) +
        ")"
      : "";

  const typologySchienale =
    context.selectedMaterialeSchienale?.material_typology &&
    context.selectedMaterialeSchienale?.material_typology != "general"
      ? "(" +
        window.jsLocale(
          `enum_material_typology_${context.selectedMaterialeSchienale?.material_typology}`
        ) +
        ")"
      : "";

  /**
   * Specifica se è possibile che lo schienale abbia stesso materiale e colore del sedile
   */
  const backSameAsSeduta = useMemo(() => {
    if (!context.configuration.data.materiale) return false;
    if (!context.configuration.data.coloreSedile) return false;

    const materialeSchienale = context.materialiSchienale.find(
      (m) => m.id == context.configuration.data.materiale
    );
    if (materialeSchienale) {
      const coloreSchienale = materialeSchienale.colori.find(
        (c) => c.id == context.configuration.data.coloreSedile
      );
      if (coloreSchienale) {
        return true;
      }
    }

    return false;
  }, [
    context.configuration.data.materiale,
    context.configuration.data.coloreSedile,
  ]);

  /**
   * Specifico se il selettore dei materiali back può essere mostrato con immagine di preview
   */
  const backWithImage = useMemo(() => {
    if (!context.materialiSchienale) return false;

    let hasImage = true;
    context.materialiSchienale.forEach((materialeSchienale) => {
      if (!materialeSchienale.image) hasImage = false;
    });

    return hasImage;
  }, [context.materialiSchienale]);

  return (
    <>
      <p className="SidebarStepsUi__label">
        {window.jsLocale("configurator_s2_title")}
      </p>
      <SelectUi
        label={window.jsLocale("configurator_product_general_material")}
        selected={
          context.selectedMaterialeSedile
            ? context.selectedMaterialeSedile?.id + " " + typology
            : ""
        }
        open={context.currentSelect == 1}
        setOpen={(open) =>
          context.setCurrentSelect(
            open ? 1 : context.currentSelect == 1 ? 0 : context.currentSelect
          )
        }
      >
        <SelectListUi
          options={context.materialiSedile}
          activeId={context.configuration.data.materiale}
          onClick={onClickMaterialeSedile}
          withCat={true}
        />
      </SelectUi>
      {context.selectedMaterialeSedile?.colori &&
        context.selectedMaterialeSedile?.colori.length > 0 && (
          <>
            <SelectUi
              label={window.jsLocale("configurator_color")}
              selected={coloreSedile}
              open={context.currentSelect == 2}
              setOpen={(open) =>
                context.setCurrentSelect(
                  open
                    ? 2
                    : context.currentSelect == 2
                    ? 0
                    : context.currentSelect
                )
              }
            >
              <SidebarColorsUi
                colors={context.selectedMaterialeSedile?.colori}
                onClick={onClickColoreSedile}
                activeColorId={context.configuration.data?.coloreSedile}
                selected={coloreLabel(context.selectedColoreSedile)}
                onMouseEnter={onHoverColoreSedile}
                onMouseLeave={onLeaveColoreSedile}
              />
              {context.selectedMaterialeSedile.scheda && (
                <a
                  href={context.selectedMaterialeSedile.scheda}
                  className="box-label box-label--download"
                  target="_blank"
                >
                  <span>
                    {window.jsLocale("configurator_material_pdf")}{" "}
                    {context.selectedMaterialeSedile?.label}.pdf
                  </span>

                  <IconUi icon="download"></IconUi>
                </a>
              )}
            </SelectUi>
          </>
        )}

      {context.materialiSchienale.length > 0 && (
        <SelectUi
          label={window.jsLocale("configurator_product_back_material")}
          disabled={context.selectedMaterialeSedile?.id ? false : true}
          selected={
            context.selectedMaterialeSchienale
              ? context.selectedMaterialeSchienale?.id + " " + typologySchienale
              : ""
          }
          open={context.currentSelect == 3}
          setOpen={(open) =>
            context.setCurrentSelect(
              open ? 3 : context.currentSelect == 3 ? 0 : context.currentSelect
            )
          }
        >
          <SelectListUi
            options={
              backSameAsSeduta
                ? [
                    {
                      id: "__SEDUTA__",
                      label: window
                        .jsLocale("configuraror_back_like_top")
                        .toUpperCase(),
                    },
                  ].concat(context.materialiSchienale)
                : context.materialiSchienale
            }
            activeId={context.configuration.data.materialeSchienale}
            onClick={onClickMaterialeSchienale}
            withCat={!backWithImage}
            withImage={backWithImage}
          />
        </SelectUi>
      )}
      {context.selectedMaterialeSchienale?.colori &&
        context.selectedMaterialeSchienale?.colori.length > 0 && (
          <>
            <SelectUi
              label={window.jsLocale("configurator_color")}
              selected={coloreSchienale}
              open={context.currentSelect == 4}
              setOpen={(open) =>
                context.setCurrentSelect(
                  open
                    ? 4
                    : context.currentSelect == 4
                    ? 0
                    : context.currentSelect
                )
              }
            >
              <SidebarColorsUi
                colors={context.selectedMaterialeSchienale?.colori}
                onClick={onClickColoreSchienale}
                activeColorId={context.configuration.data?.coloreSchienale}
                selected={coloreLabel(context.selectedColoreSchienale)}
                onMouseEnter={onHoverColoreSchienale}
                onMouseLeave={onLeaveColoreSchienale}
              />
              {context.selectedMaterialeSchienale.scheda && (
                <a
                  href={context.selectedMaterialeSchienale.scheda}
                  className="box-label box-label--download"
                  target="_blank"
                >
                  <span>
                    {window.jsLocale("configurator_material_pdf")}{" "}
                    {context.selectedMaterialeSchienale?.label}.pdf
                  </span>

                  <IconUi icon="download"></IconUi>
                </a>
              )}
            </SelectUi>
          </>
        )}
      {context.selectedMaterialeSchienale &&
        context.selectedMaterialeSchienale.texture.trapuntatura &&
        !context.TRAPUNTATURA_FORZATA &&
        !context.TRAPUNTATURA_ANNULLATA && (
          <SelectUi
            label={window.jsLocale("configurator_quilting")}
            selected={context.selectedTrapuntatura?.label}
            open={context.currentSelect == 5}
            setOpen={(open) =>
              context.setCurrentSelect(
                open
                  ? 5
                  : context.currentSelect == 5
                  ? 0
                  : context.currentSelect
              )
            }
          >
            <SelectListUi
              options={[
                {
                  id: 1,
                  label: window.jsLocale("cta_yes").toUpperCase(),
                  value: true,
                },
                {
                  id: 2,
                  label: window.jsLocale("cta_no").toUpperCase(),
                  value: false,
                },
              ]}
              activeId={context.selectedTrapuntatura?.id}
              onClick={onClickTrapuntatura}
            />
          </SelectUi>
        )}
    </>
  );
}
