import React, { useState, useEffect, useMemo, useRef } from "react";

/**
 * ConfiguratorContext
 */
export const ConfiguratorContext = React.createContext();

/**
 * Provider
 */
export function ConfiguratorProvider(props) {
  const { product, clientUnitSize, children } = props;

  const [state, setState] = useState({
    loaded: false, // load status
    loadedError: null, // load error status
    productId: null,
    productData: null,
  });

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [oldCurrentSelect, setOldCurrentSelect] = useState(1);

  const [currentSelect, setCurrentSelect] = useState(1);

  const [configuration, setConfiguration] = useState({
    step: 1,
    data: {},
  });

  const semaphore = useRef(false);

  /**
   * Initial context loading
   */

  useEffect(() => {
    if (semaphore.current) return;
    semaphore.current = true;

    const _ = async () => {
      try {
        const locale = document
          .querySelector('meta[name="js-current-locale"]')
          .getAttribute("content");
        const client = document
          .querySelector('meta[name="js-current-client"]')
          .getAttribute("content");
        const response = await fetch(
          `/product_data/${product}?locale=${locale}&client_code=${client}`
        );
        const responseJson = await response.json();
        // save product on state and update to loaded
        setState({
          loaded: true,
          productId: product,
          clientUnitSize: clientUnitSize,
          productData: responseJson,
        });

        // setup initial configuration with a selected base
        let defaultBase = responseJson.basi[0];
        let urlBaseId = getParameter("base_id");
        if (urlBaseId) {
          const base = responseJson.basi.find((b) => b.base_id == urlBaseId);
          if (base) defaultBase = base;
        }
        setConfiguration({
          ...configuration,
          step: responseJson.basi.length > 1 ? 1 : 2,
          data: {
            base: defaultBase.id,
            traslazioneY: defaultBase.gltf_translation_y,
            traslazioneZ: defaultBase.gltf_translation_z,
            rotazioneX: defaultBase.gltf_rotation_x,
          },
        });
      } catch (err) {
        console.error(err);

        setState({
          loaded: true,
          loadedError: err.message,
        });
      }
    };

    _();
  }, []);

  /**
   * Effect to animate model viewer resize when sidebar change
   */
  useEffect(() => {
    const productMain = document.querySelector("main");
    productMain.classList.add('hidden')

    setTimeout(() => {
      productMain.classList.remove('hidden')
    }, 500)
  }, [sidebarOpen])

  /**
   * Effect to sync configuration with A-Frame
   */
  useEffect(() => {
    if (!state.productData) return;

    // setup dati options da passare a product viewer
    const options = [];
    const requiredOptions = [
      "gltfModelBase",
      "textureSedile",
      "textureSchienale",
      "textureBase",
      "textureBaseGas",
      "coloreSedile",
      "coloreSchienale",
      "coloreBase",
      "trapuntatura",
      "traslazioneY",
      "traslazioneZ",
      "rotazioneX",
    ];

    // trovo il materiale scelto
    const materiale = state.productData.materiali.find(
      (m) => m.id === configuration.data?.materiale
    );
    // trovo il colore scelto
    const colore = materiale?.colori?.find(
      (c) => c.id === configuration.data?.coloreSedile
    );
    // trovo il materiale schienale scelto
    const materialeSchienale = state.productData.materiali.find(
      (m) => m.id === configuration.data.materialeSchienale
    );
    // trovo il colore schienale scelto
    const coloreSchienale = materialeSchienale?.colori?.find(
      (c) => c.id === configuration.data.coloreSchienale
    );

    // preparo base
    if (configuration.data.base) {
      options.push(`gltfModelBase: #${configuration.data.base}`);
    }

    // preparo transazione Y
    if (configuration.data.traslazioneY) {
      options.push(`traslazioneY: ${configuration.data.traslazioneY}`);
    }

    // preparo transazione Z
    if (configuration.data.traslazioneZ) {
      options.push(`traslazioneZ: ${configuration.data.traslazioneZ}`);
    }

    // preparo rotazione X
    if (configuration.data.rotazioneX) {
      options.push(`rotazioneX: ${configuration.data.rotazioneX}`);
    }

    // se e stato selezionato un materiale
    if (materiale) {
      // NOTE: Gestione custom per ORBITAL
      if (colore?.custom_orbital_texture) {
        options.push(
          `textureSedile: ${JSON.stringify(colore.custom_orbital_texture)}`
        );
        if (state.productData.force_texture_to_base)
          options.push(
            `textureBase: ${JSON.stringify(colore.custom_orbital_texture)}`
          );
        // altrimenti uso la texture del materiale
      } else {
        const materialeTexture = materiale.texture;

        // tolto perchè sennò prende le thumb
        if (colore?.model_color_texture) {
          materialeTexture.color = colore.model_color_texture;
        }

        options.push(`textureSedile: ${JSON.stringify(materialeTexture)}`);
        if (state.productData.force_texture_to_base)
          options.push(`textureBase: ${JSON.stringify(materialeTexture)}`);
      }

      if (colore) {
        options.push(`coloreSedile: ${colore.color}`);
        if (state.productData.force_texture_to_base)
          options.push(`coloreBase: ${colore.color}`);
      }

      if (configuration.data.materialeSchienale) {
        if (coloreSchienale?.custom_orbital_texture) {
          // NOTE: Gestione custom per ORBITAL
          options.push(
            `textureSchienale: ${JSON.stringify(
              coloreSchienale.custom_orbital_texture
            )}`
          );
        } else {
          const materialeSchienaleTexture = materialeSchienale.texture;
          if (coloreSchienale?.model_color_texture) {
            materialeSchienaleTexture.color =
              coloreSchienale.model_color_texture;
          }
          options.push(
            `textureSchienale: ${JSON.stringify(materialeSchienaleTexture)}`
          );
        }
      }

      if (configuration.data.coloreSchienale) {
        options.push(`coloreSchienale: ${coloreSchienale.color}`);
      }

      if (configuration.data.textureBase) {
        options.push(
          `textureBase:  ${JSON.stringify(
            configuration.data.textureBase.texture
          )}`
        );
      }

      if (configuration.data.textureBaseGas) {
        options.push(
          `textureBaseGas:  ${JSON.stringify(
            configuration.data.textureBaseGas.texture
          )}`
        );
      }

      if (configuration.data.trapuntatura) {
        const trapuntatura = configuration.data.trapuntatura.value;
        options.push(`trapuntatura: ${trapuntatura ? "YES" : ""}`);
      } else if (TRAPUNTATURA_FORZATA) {
        options.push(`trapuntatura: YES`);
      }
    }

    // applico aggiornamento a model viewer
    requiredOptions.forEach((required) => {
      const option = options.find((o) => o.includes(required + ": "));
      if (!option) options.push(required + ": ");
    });
    if (options.length > 0) {
      document
        .querySelector("a-scene")
        .setAttribute("model-viewer", options.join(";"));
    }
  }, [
    configuration.data.base,
    configuration.data.textureBase,
    configuration.data.textureBaseGas,
    configuration.data.materiale,
    configuration.data.coloreSedile,
    configuration.data.materialeSchienale,
    configuration.data.coloreSchienale,
    configuration.data.coloreBase,
    configuration.data.trapuntatura,
    configuration.data.traslazioneY,
    configuration.data.traslazioneZ,
    configuration.data.rotazioneX,
  ]);

  /**
   * @function goToNext
   * @param {*} data
   * @returns
   */
  const goToNext = (data = {}) => {
    let next = configuration.step + 1;
    if (state.productData.force_texture_to_base && next == 3) next = 4;
    if (next > 4) return;

    setConfiguration({
      step: next,
      data: Object.assign(configuration.data, data),
    });
  };

  /**
   * @function goToPrev
   * @param {*} data
   * @returns
   */
  const goToPrev = (data = {}) => {
    let prev = configuration.step - 1;
    if (state.productData.force_texture_to_base && prev == 3) prev = 2;
    if (prev < 1) return;

    setConfiguration({
      step: prev,
      data: Object.assign(configuration.data, data),
    });
  };

  /**
   * @function setData
   * Update configuration data
   * @param {*} data
   */
  const setData = (data = {}) => {
    setConfiguration({
      ...configuration,
      data: Object.assign(configuration.data, data),
    });
  };
  const setStep = (step) => {
    setConfiguration({
      ...configuration,
      step: step,
    });
  };
  const resetData = () => {
    setConfiguration({
      step: state.productData.basi.length > 1 ? 1 : 2,
      data: {
        base: state.productData.basi[0].id,
        traslazioneY: state.productData.basi[0].gltf_translation_y,
        traslazioneZ: state.productData.basi[0].gltf_translation_z,
        rotazioneX: state.productData.basi[0].gltf_rotation_x,
      },
    });
  };

  const materialiSedile = useMemo(() => {
    if (!state.productData?.materiali) return [];

    const materiali = state.productData.materiali.filter(
      (m) => m.typology === "general"
    );
    return materiali;
  }, [state.productData?.materiali]);
  const materialiSchienale = useMemo(() => {
    if (!state.productData?.materiali) return [];
    const materiali = state.productData.materiali.filter(
      (m) => m.typology === "back"
    );

    return materiali;
  }, [state.productData?.materiali]);

  const basi = useMemo(() => {
    if (!state.productData?.basi) return [];
    let basi = [];
    if (configuration.data.typologyBasi) {
      basi = state.productData.basi.filter(
        (b) => b.typology === configuration.data.typologyBasi
      );
    } else {
      basi = state.productData.basi;
    }

    // Ordina le basi per nome
    basi.sort((a, b) => (a.code || '').localeCompare(b.code || ''));

    return basi;
  }, [state.productData?.basi, configuration.data.typologyBasi]);
  const selectedTypologyBasi = useMemo(() => {
    if (!state.productData?.basi_tipologie) return null;
    const tipologie = state.productData.basi_tipologie.find(
      (m) => m.id === configuration.data.typologyBasi
    );
    return tipologie;
  }, [configuration.data.typologyBasi, state.productData?.basi_tipologie]);

  const selectedMaterialeSedile = useMemo(() => {
    if (!state.productData?.materiali) return null;
    const materiale = state.productData.materiali.find(
      (m) => m.id === configuration.data.materiale
    );
    return materiale;
  }, [configuration.data.materiale, state.productData?.materiali]);

  const selectedColoreSedile = useMemo(() => {
    if (!selectedMaterialeSedile) return null;
    const colore = selectedMaterialeSedile.colori.find(
      (c) => c.id === configuration.data.coloreSedile
    );
    return colore;
  }, [selectedMaterialeSedile, configuration.data.coloreSedile]);

  const selectedMaterialeSchienale = useMemo(() => {
    if (!state.productData?.materiali) return null;
    const materiale = state.productData.materiali.find(
      (m) => m.id === configuration.data.materialeSchienale
    );
    return materiale;
  }, [configuration.data.materialeSchienale, state.productData?.materiali]);

  const selectedColoreSchienale = useMemo(() => {
    if (!selectedMaterialeSchienale) return null;
    const colore = selectedMaterialeSchienale.colori.find(
      (c) => c.id === configuration.data.coloreSchienale
    );
    return colore;
  }, [selectedMaterialeSchienale, configuration.data.coloreSchienale]);

  const selectedTrapuntatura = useMemo(() => {
    if (!selectedMaterialeSchienale) return null;

    const trapuntatura = configuration.data.trapuntatura;
    return trapuntatura;
  }, [selectedMaterialeSchienale, configuration.data.trapuntatura]);

  const selectedBase = useMemo(() => {
    if (!state.productData?.basi) return null;

    const base = state.productData?.basi.find(
      (b) => b.id === configuration.data.base
    );

    base.typologyLabel = state.productData.basi_tipologie.find(
      (t) => t.id === base.typology
    )?.label;

    return base;
  }, [configuration.data.basi, configuration.data.base]);

  const selectedMaterialeBase = useMemo(() => {
    if (!selectedBase) return null;

    const materiale = selectedBase.textures.find(
      (t) => t.id === configuration.data.textureBase?.id
    );
    return materiale;
  }, [selectedBase, configuration.data.textureBase]);

  const selectedMaterialeBaseGas = useMemo(() => {
    if (!selectedBase) return null;

    const materiale = selectedBase.textures.find(
      (t) => t.id === configuration.data.textureBaseGas?.id
    );
    return materiale;
  }, [selectedBase, configuration.data.textureBaseGas]);

  // HARCODE: Lista sedie che forzatamente hanno sempre lo schienale trapuntato.
  const TRAPUNTATURA_FORZATA = useMemo(() => {
    if (!state.productData) return false;
    return ["SEDIA-ROMA-2"].includes(state.productData.id);
  }, [state.productData]);

  // HARCODE: Lista sedie che forzatamente non hanno mai la trapuntatura.
  const TRAPUNTATURA_ANNULLATA = useMemo(() => {
    if (!state.productData) return false;
    return ["SGABELLO-STEVE", "SGABELLO-CENTER"].includes(state.productData.id);
  }, [state.productData]);

  const numberBasi = useMemo(() => {
    if (!state.productData?.basi) return null;
    return state.productData?.basi.length;
  }, [state.productData?.basi]);

  const canGoTo = (step) => {
    const { data } = configuration;
    let valid = true;
    if (step === 1) {
      if (!data.base) valid = false;
    }
    if (step === 2) {
      if (
        !data.materiale ||
        !data.coloreSedile ||
        (materialiSchienale.length > 0 && !data.materialeSchienale) ||
        (selectedMaterialeSchienale &&
          selectedMaterialeSchienale.colori.length > 0 &&
          !data.coloreSchienale) ||
        (selectedMaterialeSchienale &&
          selectedMaterialeSchienale.texture.trapuntatura &&
          !TRAPUNTATURA_FORZATA &&
          !TRAPUNTATURA_ANNULLATA &&
          !data.trapuntatura)
      )
        valid = false;
    }
    if (step === 3) {
      if (!data.textureBase) valid = false;
    }

    return valid;
  };

  const canGoToNext = useMemo(() => {
    const { step } = configuration;

    return canGoTo(step);
  }, [configuration]);

  return (
    <ConfiguratorContext.Provider
      value={{
        state,
        configuration,
        setData,
        setStep,
        resetData,
        goToNext,
        goToPrev,
        canGoToNext,
        sidebarOpen,
        setSidebarOpen,
        currentSelect,
        setCurrentSelect,
        setOldCurrentSelect,
        oldCurrentSelect,
        selectedMaterialeSedile,
        selectedColoreSedile,
        selectedColoreSchienale,
        selectedMaterialeSchienale,
        selectedTrapuntatura,
        selectedMaterialeBase,
        selectedMaterialeBaseGas,
        selectedBase,
        materialiSedile,
        materialiSchienale,
        selectedTypologyBasi,
        basi,
        numberBasi,
        canGoTo,
        TRAPUNTATURA_FORZATA,
        TRAPUNTATURA_ANNULLATA,
      }}
    >
      {children}
    </ConfiguratorContext.Provider>
  );
}

/**
 * withConfiguratorContext
 */
export function withConfiguratorContext(Component) {
  class ComponentWithContext extends React.Component {
    render() {
      return (
        <ConfiguratorContext.Consumer>
          {(value) => <Component {...this.props} provider={value} />}
        </ConfiguratorContext.Consumer>
      );
    }
  }

  return ComponentWithContext;
}

// HELPERS

const getParameter = (key) => {
  // Address of the current window
  address = window.location.search;

  // Returns a URLSearchParams object instance
  parameterList = new URLSearchParams(address);

  // Returning the respected value associated
  // with the provided key
  return parameterList.get(key);
};
